.root {
  position: fixed;
  //overflow: hidden;
  overflow-y: scroll;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0,0,0,.75);
  display: flex;
}
.modal {
  margin: auto;
  background-color: var(--color-white);
  position: relative;
  overflow: auto;

  animation-duration: 300ms;
  animation-name: openModal;

  .control {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1100;
    .label {
      line-height: 24px;
      margin-right: 8px;
    }
  }
  .content {
    width: 100%;
    height: 100%;
  }
}
.modalDesktop {
  //border-radius: 32px;
  //background-color: yellow;
  div {
    border-radius: 16px !important;
    width: 800px;
    height: 460px;
    div {
      padding: 0;
    }
  }
  //div {
  //  background-color: green;
  //}
}

@keyframes openModal {
  from {
    scale: 30% 30%;
  }

  to {
    scale: 100% 100%;
  }
}