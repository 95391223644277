.instruction {
  margin-top: 80px;
}

.blocks {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
}

.block {
  flex: 1;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--color-background-one);
  &.outlined {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
  }
  &.bgTwo {
    background-color: var(--color-background-two);
  }
  &.bgThree {
    background-color: var(--color-background-three);
  }
  .text {
    margin-top: 16px;
  }
}