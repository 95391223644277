.layout {
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    //margin-bottom: 80px;
}

.content {
    flex: 1 1;
}