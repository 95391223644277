.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
  flex-direction: row;
  @media screen and (max-width: 930px) {
    gap: 20px;
    margin-top: 20px;
  }
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }

  .card {
    flex: 1 1;
    position: relative;
    height: 300px;
    @media screen and (max-width: 840px) {
      flex: auto;
      width: calc(100% - 32px);
    }

    .wrapper {
      width: calc(100% - 32px);
      @media screen and (max-width: 840px) {
        width: 100%;
      }
      position: absolute;
      background-color: var(--color-white);
      border: 1px solid var(--color-border);
      border-radius: 16px;
      padding: 16px;
      &.opened {
        z-index: 1000;
        /* Rectangle 64 */
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      }
    }

    .content {
      display: flex;
      height: calc(300px - 32px);
      flex-direction: column;
      gap: 16px;

      .cover {
        flex: 1;
        background-color: #fff;
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
      }

      .open {
        color: var(--color-action);
        font-weight: bold;
        cursor: pointer;
      }

    }

    .text {
      margin-top: 16px;
    }


  }

}