.header {
  width: calc(100% - 30px);
  max-width: 1160px;
  padding: 0 15px 0 15px;
  margin: 16px auto;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 144px;
    height: 22px;
  }
}

.buttonsRow {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  button {
    width: calc((100% - 8px) / 2);
  }
}

.buttonsLine {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  button {
    width: 100%;
  }
}