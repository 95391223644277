.contacts {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 80px  ;
}

.wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.mail {
  width: calc((100% - 40px) / 2);
  @media screen and (max-width: 1100px) {
    width: calc(((100% - 40px) / 2));
  }
  @media screen and (max-width: 930px) {
    width: calc(((100% - 20px) / 2));
  }
  @media screen and (max-width: 840px) {
    width: 100%
  }
}

.legal {
  display: flex;
  gap: 40px;
  //justify-content: space-between;
  align-items: stretch;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }

}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 32px);

  padding: 16px;

  border-radius: 16px;
  border: 1px solid #D9D9D9;

  font-size: 15px;
  font-weight: normal;
  .title {
    font-size: 18px;
    font-weight: bold;
  }

  a {
    color: #082046;
    font-weight: bold;
  }
}