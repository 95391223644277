.page {
  margin-top: 80px;
}

.services {
  display: grid;
  grid-template-columns: repeat(var(--columns),minmax(0,1fr));
  grid-column-gap: var(--gutter);
  align-items: start;
  margin-top: 40px;
}

.column {
  display: grid;
  grid-template-columns: minmax(0,1fr);
  row-gap: var(--gutter);
}

.card {
  position: relative;
  box-sizing: border-box;

  .wrapper {
    width: calc(100% - 32px);
    min-height: 158px;
    border-radius: 16px;
    padding: 16px;
    @media screen and (max-width: 560px) {
      min-height: max-content;
    }

    background-color: var(--color-background-one);
    &.bgTwo {
      background-color: var(--color-background-two);
    }
    &.bgThree {
      background-color: var(--color-background-three);
    }


    &.opened {
      z-index: 1000;
      /* Rectangle 64 */
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    }
  }

  .content {
    display: flex;
    //height: calc(300px - 32px);
    flex-direction: column;
    gap: 16px;

    .icon {
      width: 64px;
      height: 64px;
      border-radius: 16px;
    }

    .open {
      font-weight: bold;
      cursor: pointer;
      color: var(--color-action);
    }

  }

  .text {
    margin-top: 16px;
  }
}