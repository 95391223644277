.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  //width: calc(100% - 80px);
  //height: calc(100% - 80px);
  padding: 40px;
  text-decoration: none;
  background-size: cover;
  background-position: center bottom;
}

.disclamer {
  padding: 16px;
  font-size: 12px;
}

.logo {

}

.headline {
  font-family: "Dela Gothic One";
  font-size: 30px;
  color: #87C174;
  max-width: 360px  ;
}

.button {
  height: 48px;
  font-size: 16px;
  font-weight: bold;
}