.page {
  margin-top: 80px;
  p {
    display: block;
    max-width: 560px;
  }
}

.text {
  max-width: 560px;
  font-size: 15px;
  line-height: 24px;
  h2 {
    margin-bottom: 40px;
    &.success {
      color: var(--color-green);
    }
  }
}