.background {

  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 80px;
  margin-bottom: 80px;

  background-position: 50% 50%;
  background-size: cover;

  .wrapper {

    background-repeat: no-repeat;
    background-position: 60% 50%;

    h2 {
      margin-bottom: 40px;
    }

    form {
      max-width: 360px;
    }
  }

}