.block {
  margin-top: 80px;
}

.reviews {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
}

.review {
  width: calc((100% - 40px) / 2);
  @media screen and (max-width: 840px) {
    width: 100%;
  }
  display: flex;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--color-border);
  padding: 16px;
  box-sizing: border-box;
  .left {
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
    }
  }
  .right {
    .name {
      font-weight: bold;
    }
    .text {
      margin-top: 16px;
    }
  }
}