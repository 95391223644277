.footer {
  margin-top: 80px;
  min-height: 230px;
  background-color: var(--color-background-one);
  background-size: cover;
  background-position: center center;

  .content {

    max-width: 1160px;
    margin: auto;
    padding: 40px 15px 40px 15px;

    font-size: 12px;
    p {
      margin-top: 0;
      margin-bottom: 8px;
    }

    a {
      color: var(--color-text);
    }
    a:visited {
      color: var(--color-text);
    }

    .logo {
      img {
        width: 144px;
        height: 22px;
      }
    }

    .wrap {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
      margin-top: 24px;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        gap: 24px;
      }

      .caption {
        //width: calc((100% - (5 * 40px)) / 6);
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 510px) {
          width: 100%;
        }
      }
      .menu {
        //width: calc((100% - (5 * 40px)) / 6);
        @media screen and (max-width: 1024px) {
          width: calc((100% - 24px) / 2);
        }
        @media screen and (max-width: 510px) {
          width: calc((100% - 24px) / 2);
        }
      }
      .policy {
        //width: calc((100% - (5 * 40px)) / 6);
        @media screen and (max-width: 1024px) {
          width: calc((100% - 24px) / 2);
        }
        @media screen and (max-width: 510px) {
          width: calc((100% - 24px) / 2);
        }
      }
      .legal {
        //width: calc((100% - (5 * 40px)) / 6);
        @media screen and (max-width: 1024px) {
          width: calc((100% - 24px) / 2);
        }
        @media screen and (max-width: 510px) {
          width: calc((100% - 24px) / 2);
        }
      }
    }

  }

}